import { siteHome, siteHomeSave } from '../libs/api';
import { sleep, uploadProgress, reverseMapping } from '../helpers/utils';

export default function (Alpine) {
    Alpine.store('articleQuery', {
        selected: null,
        list: [],
        def: {
            id: null,
            title: '',
        },
        init() {
            this.selected = this.def;
        },
        save() {
            if (this.selected.id !== null) {
                const maxSort = this.list.length === 0 ? 0 : Math.max(...this.list.map((o) => o.sort));
                this.list.push({ ...this.selected, sort: maxSort + 1 });
            }
        },
        reset() {
            this.selected = this.def;
        },
        remove(id) {
            this.list = this.list.filter((i) => +i.id != +id);
        },
        clear() {
            this.list = [];
            this.selected = null;
        },
    });
    Alpine.store('categoryQuery', {
        selected: null,
        list: [],
        def: {
            id: null,
            title: '',
        },
        init() {
            this.selected = this.def;
        },
        save() {
            if (this.selected.id !== null) {
                const maxSort = this.list.length === 0 ? 0 : Math.max(...this.list.map((o) => o.sort));
                this.list.push({ ...this.selected, sort: maxSort + 1 });
            }
        },
        reset() {
            this.selected = this.def;
        },
        remove(id) {
            this.list = this.list.filter((i) => +i.id != +id);
        },
        clear() {
            this.list = [];
            this.selected = null;
        },
    });

    Alpine.data('homeSettings', () => ({
        loaded: false,

        data: {
            type: 'featured',
            cover: null,
            title: '',
            content: '',
            link: '',
        },

        articles: [],
        categories: [],

        async init() {
            Alpine.store('articleQuery').clear();
            Alpine.store('categoryQuery').clear();
            
            await this.setting();
        },

        async setting() {
            const { code, data } = await siteHome();
            this.loaded = true;
            if (code === 1) {
                this.data = {
                    content: data.content || '',
                    cover: data.cover || null,
                    link: data.link || '',
                    title: data.title || '',
                    type: data.type || 'featured',
                };

                this.articles = data.articles || [];
                this.categories = data.categories || [];

                const cquery = Alpine.store('categoryQuery');
                cquery.list = [...this.categories];

                const aquery = Alpine.store('articleQuery');
                aquery.list = [...this.articles];

                console.log(cquery, aquery);
            }
        },

        save: {
            async ['@click']() {
                await this.saving();
            },
        },

        async saving() {
            const payload = {
                ...this.data,
                articles: this.articles,
                categories: this.categories,
            };

            const { code, data } = await siteHomeSave(payload);
            if (code === 1) {
                window.sendEvent('toast', { title: '已儲存。' });
            }

            window.sendEvent('validation::done');
        },

        upload() {
            const that = this;
            return async (files, limit) => {
                const result = await uploadProgress(files, limit);
                if (result.success === true) {
                    that.data.cover = result.path;
                }

                return result;
            };
        },

        sortArticle() {
            const that = this;
            return (event) => {
                const ta = $(event.target);
                const sort = ta.find('[data-id]').map(function (ele) {
                    return $(this).data('id');
                });

                const sortMap = reverseMapping(sort);

                that.articles = that.articles.map((i) => {
                    i.sort = +sortMap[i.id];
                    return i;
                });
            };
        },

        sortCategory() {
            const that = this;
            return (event) => {
                const ta = $(event.target);
                const sort = ta.find('[data-id]').map(function (ele) {
                    return $(this).data('id');
                });

                const sortMap = reverseMapping(sort);

                that.categories = that.categories.map((i) => {
                    i.sort = +sortMap[i.id][0];
                    return i;
                });

                console.log(that.categories, '>==');
            };
        },

        addArticle: {
            ['@click']() {
                const that = this;
                sendEvent('modal', {
                    title: '新增文章',
                    html: '/pages/press/query-article.html',
                    closeBtn: true,
                    onClose: () => {
                        const query = Alpine.store('articleQuery');

                        that.articles = [...query.list];
                    },
                });
            },
        },

        addCategory: {
            ['@click']() {
                const that = this;
                sendEvent('modal', {
                    title: '新增專欄',
                    html: '/pages/press/query-category.html',
                    closeBtn: true,
                    onClose: () => {
                        const query = Alpine.store('categoryQuery');

                        that.categories = [...query.list];
                    },
                });
            },
        },

        del: {
            ['@click']() {
                const type = $(this.$el).data('type');

                if (type === 'article') {
                    const query = Alpine.store('articleQuery');

                    this.articles = this.articles.filter((i) => +i.id != +this.item.id);
                    query.remove(+this.item.id);
                } else if (type === 'category') {
                    const query = Alpine.store('categoryQuery');

                    this.categories = this.categories.filter((i) => +i.id != +this.item.id);
                    query.remove(+this.item.id);
                }
            },
        },
    }));
}
