import { url } from '../helpers/utils';
import { elementImageUpload } from '../libs/api';

import Header from '@editorjs/header';
import List from '@editorjs/list';
// import NestedList from '@editorjs/nested-list';

// import Paragraph from '@editorjs/paragraph';
import AnchorTune from '../libs/editor.js/editorjs-anchor';
// import Album from '../libs/editor.js/album/album';
// import AnchorBlockTune from '../libs/editor.js/editorjs-anchor.js';
// import FontColor from '../libs/editor.js/editorjs-text-color-plugin.js';
// import ImageTool from '@editorjs/image';
import ImageTool from 'editorjs-image-with-link';

import TextVariantTune from '@editorjs/text-variant-tune';
// import Table from '@editorjs/table';
import Embed from '@editorjs/embed';

import Quote from '@editorjs/quote';
// import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';
import PaidSection from './editor.js/paid-section.js';

// console.log(AlpineI18n);

export default function (AlpineI18n) {
    return {
        minHeight: 100,
        logLevel: 'VERBOSE',
        // minHeight: 300,
        placeholder: AlpineI18n.t('editor.enter_content'),
        tools: {
            header: {
                class: Header,
                inlineToolbar: ['link' /*'fontColor'*/],
                config: {
                    placeholder: AlpineI18n.t('editor.enter_title'),
                    levels: [2, 3, 4 /*5, 6*/],
                    defaultLevel: 3,
                },
                shortcut: 'CMD+SHIFT+H',
            },
            image: {
                class: ImageTool,
                inlineToolbar: ['link'],
                config: {
                    field: 'file',
                    types: 'image/png, image/jpg, image/bmp, image/gif',
                    uploader: {
                        async uploadByFile(file) {
                            if (!window.FileReader) {
                                return {
                                    success: 0,
                                };
                            }

                            if (file.size / 1024 ** 2 >= 5) {
                                return {
                                    success: 0,
                                };
                            }

                            // const url = 'element/upload/image';

                            const {
                                code,
                                data: { path },
                            } = await elementImageUpload({ file: file });

                            return {
                                success: 1,
                                file: {
                                    url: url(import.meta.env.VITE_CDN_BASE, path),
                                },
                            };

                            // const api = alpine.store('api').getClient();
                            // return api
                            //  .postForm(url, { file: file })
                            //  .then((result) => {
                            //      return {
                            //          success: 1,
                            //          file: {
                            //              url: window.site_url(result.data.path, 'cdn'),
                            //          },
                            //      };
                            //  })
                            //  .catch((error) => console.log('Editor::uploadByFile', error));
                        },
                        uploadByUrl(url) {
                            return fetch(url)
                                .then((response) => response.blob())
                                .then(async (imageBlob) => {
                                    const filename =
                                        'f' +
                                        Math.floor((1 + Math.random()) * 0x10000)
                                            .toString(16)
                                            .substring(1);
                                    const file = new File(
                                        [imageBlob],
                                        `${filename}.${imageBlob.type.split('/').slice(-1).pop()}`,
                                        { type: imageBlob.type },
                                    );

                                    return this.uploadByFile(file);
                                });
                        },
                    },
                    // endpoints: {},
                },
            },
            // album: {
            //  class: Album,
            //  inlineToolbar: true,
            //  config: {
            //      async uploader(file) {
            //          const {
            //              code,
            //              data: { path },
            //          } = await elementImageUpload({ file: file });

            //          return url(import.meta.env.VITE_CDN_BASE, path);
            //          // const url = 'element/upload/image';

            //          // const api = alpine.store('api').getClient();
            //          // return api
            //          //  .postForm(url, { file: file })
            //          //  .then((result) => {
            //          //      return window.site_url(result.data.path, 'cdn');
            //          //  })
            //          //  .catch((error) => console.log('Editor::uploadByFile', error));
            //      },
            //  },
            // },
            list: {
                class: List,
                inlineToolbar: true,
                shortcut: 'CMD+SHIFT+L',
            },

            // fontColor: {
            //     class: FontColor,
            //     config: {
            //         colors: ['#6aa2b9', '#28a745', '#ffc107', '#dc3545', '#b38a5f'],
            //         columnsCount: 4,
            //     },
            // },
            Marker: {
                class: Marker,
                shortcut: 'CMD+SHIFT+M',
            },
            paragraph: {
                inlineToolbar: true,
            },

            PaidSection: {
                class: PaidSection,
            },
            // table: {
            //  class: Table,
            //  inlineToolbar: true,
            //  config: {
            //      rows: 2,
            //      cols: 3,
            //  },
            //  shortcut: 'CMD+ALT+T',
            // },
            // warning: {
            //  class: Warning,
            //  inlineToolbar: true,
            //  config: {
            //      titlePlaceholder: AlpineI18n.t('editor.title'),
            //      messagePlaceholder: AlpineI18n.t('editor.enter_message'),
            //  },
            // },

            embed: {
                class: Embed,
                config: {
                    services: {
                        youtube: {
                            regex: /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
                            embedUrl: 'https://www.youtube.com/embed/<%= remote_id %>',
                            html: '<iframe height="315" height="315" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                            id: ([id, params]) => {
                                if (!params && id) {
                                    return id;
                                }

                                const paramsMap = {
                                    start: 'start',
                                    end: 'end',
                                    t: 'start',
                                    // eslint-disable-next-line camelcase
                                    time_continue: 'start',
                                    list: 'list',
                                };

                                params = params
                                    .slice(1)
                                    .split('&')
                                    .map((param) => {
                                        const [name, value] = param.split('=');

                                        if (!id && name === 'v') {
                                            id = value;
                                            return null;
                                        }

                                        if (!paramsMap[name]) {
                                            return null;
                                        }

                                        return `${paramsMap[name]}=${value}`;
                                    })
                                    .filter((param) => !!param);

                                return id + '?' + params.join('&');
                            },
                        },
                        soundcloud: {
                            regex: /https?:\/\/(?:w\.|www\.|)(?:soundcloud\.com\/)(?:(?:player\/\?url=https\%3A\/\/api.soundcloud.com\/tracks\/)|)(((\w|-)[^A-z]{7})|([A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*(?!\/sets(?:\/|$))(?:\/[A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*){1,2}))/,
                            embedUrl:
                                'https://w.soundcloud.com/player/?url=https://soundcloud.com/<%= remote_id %>&amp;color=%23463d2e&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true',
                            html: '<iframe frameborder="no" height="166" width="100%" scrolling="no"></iframe>',
                            id: ([id, params]) => {
                                return id;
                            },
                        },
                    },
                },
            },

            // tmplCode: tmplCode,
            quote: {
                class: Quote,
                inlineToolbar: true,
                shortcut: 'CMD+SHIFT+O',
                config: {
                    quotePlaceholder: AlpineI18n.t('editor.enter_quote'),
                    captionPlaceholder: AlpineI18n.t('editor.enter_author'),
                },
            },

            delimiter: Delimiter,

            anchorTune: AnchorTune,
            textVariant: TextVariantTune,
        },

        i18n: {
            messages: {
                ui: {
                    blockTunes: {
                        toggler: {
                            'Click to tune': AlpineI18n.t('editor.click_to_tune'),
                            'or drag to move': AlpineI18n.t('editor.drag_to_move'),
                        },
                    },
                    inlineToolbar: {
                        converter: {
                            'Convert to': AlpineI18n.t('editor.convert_to'),
                        },
                    },
                    toolbar: {
                        toolbox: {
                            Add: AlpineI18n.t('editor.add'),
                        },
                    },
                    popover: {
                        Filter: AlpineI18n.t('editor.filter'),
                        'Nothing found': AlpineI18n.t('editor.nothing_found'),
                    },
                },

                toolNames: {
                    Text: AlpineI18n.t('editor.text'),
                    Heading: AlpineI18n.t('editor.heading'),
                    List: AlpineI18n.t('editor.list'),
                    Warning: AlpineI18n.t('editor.warning'),
                    Checklist: AlpineI18n.t('editor.checklist'),
                    Quote: AlpineI18n.t('editor.quote'),
                    Code: AlpineI18n.t('editor.code'),
                    Delimiter: AlpineI18n.t('editor.delimiter'),
                    'Raw HTML': AlpineI18n.t('editor.raw_html'),
                    Table: AlpineI18n.t('editor.table'),
                    Link: AlpineI18n.t('editor.link'),
                    Marker: AlpineI18n.t('editor.marker'),
                    Bold: AlpineI18n.t('editor.bold'),
                    Italic: AlpineI18n.t('editor.italic'),
                    InlineCode: AlpineI18n.t('editor.inline_code'),
                    Image: AlpineI18n.t('editor.image'),
                    // Album: AlpineI18n.t('editor.album'),
                },

                tools: {
                    warning: {
                        Title: AlpineI18n.t('editor.title'),
                        Message: AlpineI18n.t('editor.message'),
                    },

                    link: {
                        'Add a link': AlpineI18n.t('editor.add_link'),
                    },

                    stub: {
                        'The block can not be displayed correctly.': AlpineI18n.t('editor.block_display_error'),
                    },
                    header: {
                        Header: AlpineI18n.t('editor.heading'),
                        'Heading 1': AlpineI18n.t('editor.heading_1'),
                        'Heading 2': AlpineI18n.t('editor.heading_2'),
                        'Heading 3': '標題 1', // AlpineI18n.t('editor.heading_3'),
                        'Heading 4': '標題 2', // AlpineI18n.t('editor.heading_4'),
                        'Heading 5': AlpineI18n.t('editor.heading_5'),
                        'Heading 6': AlpineI18n.t('editor.heading_6'),
                    },
                    image: {
                        'Select an Image': AlpineI18n.t('editor.select_image'),
                        Caption: AlpineI18n.t('editor.caption'),
                        'Couldn’t upload image. Please try another.': AlpineI18n.t('editor.upload_failed'),
                        'With border': AlpineI18n.t('editor.with_border'),
                        'Stretch image': AlpineI18n.t('editor.stretch_image'),
                        'With background': AlpineI18n.t('editor.with_background'),
                    },
                },

                blockTunes: {
                    delete: {
                        Delete: AlpineI18n.t('editor.delete'),
                        'Click to delete': AlpineI18n.t('editor.click_to_delete'),
                    },
                    moveUp: {
                        'Move up': AlpineI18n.t('editor.move_up'),
                    },
                    moveDown: {
                        'Move down': AlpineI18n.t('editor.move_down'),
                    },

                    filter: {
                        Filter: AlpineI18n.t('editor.filter'),
                    },
                },
            },
        },
    };
}
